import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  ImageField,
  List,
  maxLength,
  TextField,
  TextInput,
} from 'react-admin';

import {Partner, PermissionDto} from '../../api/generated';
import {FilterResetButton} from '../../components/FilterResetButton';
import {FilterSpacer} from '../../components/FilterSpacer';
import {dateRangeFilters} from '../../components/Form/Filters/dateRangeFilters';
import {Icons} from '../../components/Icon';
import {ListTopBar} from '../../components/ListTopBar';
import {PermissionGuard} from '../../components/PermissionGuard';
import {RenderConditionalLabel} from '../../components/RenderLabel';
import {usePermissions} from '../../hooks/usePermissions';
import {alphanumericValidator} from '../../utils/input.utils';
import {NamesSelectInput} from './helpers';

const partnerFilters = [
  <TextInput
    key="idFilter"
    label="Identifiant partenaire"
    title="Maximum de trois caractères (alphanumériques)"
    source="idPart"
    validate={[maxLength(3), alphanumericValidator]}
    alwaysOn
    resettable
    InputProps={{
      endAdornment: <Icons.Search size={20} />,
    }}
  />,
  <NamesSelectInput
    key="namesFilter"
    label="Libellés partenaire"
    source="names"
    alwaysOn
  />,
  ...dateRangeFilters(),
  <FilterSpacer key="spacer" source="spacer" alwaysOn />,
  <FilterResetButton key="reset" source="reset" alwaysOn />,
];

export const PartnerList = (): JSX.Element => {
  const {can} = usePermissions();

  const actions: JSX.Element[] = [];

  if (can(PermissionDto.PartnerWrite)) {
    actions.push(
      <CreateButton
        variant="contained"
        key="create"
        icon={<Icons.Add isActive size={17} />}
      />,
    );
  }

  return (
    <PermissionGuard permission={PermissionDto.PartnerRead}>
      <List
        actions={<ListTopBar actions={actions} />}
        filters={partnerFilters}
        sort={{field: 'createdAt', order: 'DESC'}}>
        <Datagrid
          bulkActionButtons={false}
          rowClick="show"
          sx={{
            '& .RaDatagrid-headerCell:nth-of-type(5)': {textAlign: 'center'}, // centered logo header
            '& .RaDatagrid-rowCell:nth-of-type(5)': {textAlign: 'center'}, // centered partner name
          }}>
          <TextField
            source="datamatrixPartnerId"
            label="Identifiant partenaire"
          />
          <DateField source="createdAt" showTime locales="fr-FR" />
          <TextField source="name" />
          <TextField source="displayOrderInApp" />
          <FunctionField
            source="isDisplayed"
            render={(record: Partner): JSX.Element => (
              <RenderConditionalLabel
                condition={record.isDisplayed}
                trueText="visible"
                falseText="invisible"
              />
            )}
          />
          <ImageField source="logo" textAlign="center" />
        </Datagrid>
      </List>
    </PermissionGuard>
  );
};
