import Stack from '@mui/material/Stack';
import {FC, useState} from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Pagination,
  TextField,
  useListContext,
  useRecordContext,
  useTranslate,
  WrapperField,
} from 'react-admin';

import {
  Client,
  KycWorkflow,
  useGetNirioUserKycWorkflowsQuery,
} from '../../api/generated';
import {ExternalLink} from '../../components/ExternalLink';
import {muiTheme} from '../../theme/defaultMuiTheme';

export const KycWorkflowsList: FC = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [sort, setSort] = useState({
    field: 'createdAt',
    order: 'DESC',
  } as const);

  const {nirioUserId} = useRecordContext<Client>();
  const {data: currentData, previousData} = useGetNirioUserKycWorkflowsQuery({
    variables: {
      nirioUserId: nirioUserId || '',
      page: page - 1,
      perPage,
      sortField: sort.field,
      sortOrder: sort.order,
    },
  });

  const {
    kycWorkflowsWithCount: {items, total},
  } = currentData ?? previousData ?? {kycWorkflowsWithCount: {}};

  const listContext = useListContext({
    data: items,
    total,
    page,
    setPage,
    perPage,
    setPerPage,
    sort,
    setSort,
    isLoading: !items,
  });

  const translate = useTranslate();

  return (
    <ListContextProvider value={listContext}>
      <Datagrid data={items} sort={sort} resource="KycWorkflow">
        <DateField source="createdAt" showTime />
        <FunctionField
          source="cisDocumentId"
          render={(record: KycWorkflow): JSX.Element => (
            <ExternalLink href={record.externalUrl ?? undefined}>
              {record.cisDocumentId ?? 'Ø'}
            </ExternalLink>
          )}
        />
        <WrapperField source="stage">
          <Stack direction="column">
            <TextField source="stage" />
            <TextField
              source="abortedReason"
              sx={{
                color: muiTheme.palette.error.main,
                fontStyle: 'italic',
              }}
            />
          </Stack>
        </WrapperField>
        <FunctionField
          source="verificationType"
          render={(record: KycWorkflow): string =>
            record.isManual
              ? translate('resources.KycWorkflow.fields.manual')
              : translate('resources.KycWorkflow.fields.automatic')
          }
        />
      </Datagrid>
      <Pagination />
    </ListContextProvider>
  );
};
